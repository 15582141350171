<template>
  <div class="app">
    <DialogPopup class="dialog login" v-if="showLogin" :show="showLogin" @cancel="hideLogin" @confirm="doLogin" :showCancel="false" :title="'Login'" :confirmText="`Login`" :canCancel="false">
      <div class="logo">
        <img style="width: 280px" src="/images/p_logo.png" alt="Logo"/>
      </div>
      <div class="login_title">
        従業員ログイン
      </div>
      <div class="login_inputline">
        <label><span>Username</span>
          <input type="text" name="username" v-model="loginUsername" placeholder="Please input username" />
        </label>
      </div>
      <div class="login_inputline">
        <label><span>Password</span>
          <input type="password" name="password" v-model="loginPassword" placeholder="Please input password" />
        </label>
      </div>
    </DialogPopup>
  </div>
</template>

<script>

import DialogPopup from "@/components/DialogPopup.vue";
import {login} from '@/api/user'
import emitter from "@/utils/emitter";

export default {
  components: {
    DialogPopup
  },
  data() {
    return {
      tables:  []      ,
      positions:  []      ,
      selectedTable: null,
      showPopup: false,
      popupTableId: null,
      popupMode: null,
      showLogin: true,
      loginUsername: null,
      loginPassword: null,
      lastTimestamp: 0,
      AutoEnable: false
    };
  },
  methods: {
    doLogin() {
      if (this.loginPassword != null && this.loginUsername != null) {
        login({
          userName: this.loginUsername,
          password: this.loginPassword
        }).then((res) => {
          if (res.data.code == 0) {
            // Login success
            localStorage.setItem("token", res.data.result.token);
            emitter.emit("login");
            this.showLogin = false;
            location.replace("/#/");
          }
          else {
            // this.$toast.error(res.data.message);
            alert(res.data.message)
          }
        }).catch((err) => {
          this.$toast.error("Login failed");
          console.error(err);
        })
      }
      else {
        this.$toast.error("Please input username and password");
      }
    },

    showTableDetail(table) {
      this.selectedTable = table;
    },
    showModePopup(table) {
      this.popupTableId = table.id;
      this.popupMode = table.mode;
      if(table.sensorEnable == 1){
          this.AutoEnable = true
        }else{
          this.AutoEnable = false
       }
      this.showPopup = true;
    },
    hidePopup() {
      this.showPopup = false;
    },
    hideLogin() {
      this.showLogin = false;
    },
    selectMode(item) {
      console.log(item);
    },
    confirmMode() {
      console.log(this.popupTableId)
      console.log(this.popupMode)
      for (const element of this.tables) {
        if (element.id == this.popupTableId) {
          element.mode = this.popupMode;
          break;
        }
      }
    },

    resizeHandler() {
      // 计算窗口宽度和高度
      const vw = window.innerWidth;
      const vh = window.innerHeight;

      // 计算窗口宽高比例
      const aspectRatio = vw / vh;
      const defaultAspectRatio = 1920 / 1080;

      // 计算适当的页面大小并根据窗口大小进行缩放
      // 根据页面大小调整背景图片大小
      if (aspectRatio > defaultAspectRatio) {
        this.$el.style.width = `1920px`;
        this.$el.style.height = `1080px`;
        this.$el.style.transform = `translate(-50%, -50%) scale(${vh / 1080})`;
      } else {
        this.$el.style.width = `1920px`;
        this.$el.style.height = `1080px`;
        this.$el.style.transform = `translate(-50%, -50%) scale(${vw / 1920})`;
      }

    },

  },

  mounted() {
    // 将页面居中
    this.$el.style.position = 'absolute';
    this.$el.style.top = '50%';
    this.$el.style.left = '50%';

    // 初始化窗口大小
    // this.resizeHandler();

    // // 监听窗口大小变化
    // window.addEventListener('resize', this.resizeHandler);
  },

  beforeUnmount() {
    // 在组件销毁之前移除窗口大小监听器
    window.removeEventListener('resize', this.resizeHandler);
  },
};

</script>

<style scoped>
.app {
  /*background-color: #C6E0B4;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  font-family: "YU Gothic UI", "MS Pゴシック", ui-sans-serif;
}


.dialog {
  font-size: 2rem;
}

.dialog .tableName {
  text-align: center;
  color: white;
  font-weight: bold;
  background: #375623;
}

.dialog .option {
  color: #375623;
  margin-top: 20px;
  margin-left:20px;
  margin-right:20px;
}

.dialog .option input[type='radio']{
    width: 20px;
    height: 20px;
}

.dialog .option .subline {
  display: block;
  font-size: 1rem;
  color: gray;
}

.dialog.login div {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}

.dialog.login input {
  font-size: 1.5rem;
}

.dialog.login label span {
  color: black;
  display:inline-block;
  width: 200px;
}

.logo {
  margin-top: 20px;
}

</style>
