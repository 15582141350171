<template>
  <div class="table-usage">
    <!-- Display the table usage data here -->

    <div id="chart">
      <ApexChart type="heatmap" height="350" width="500" :options="chartOptions" :series="series"></ApexChart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import {getUseCountData} from "@/api/tables";
import emitter from "@/utils/emitter";
export default {
  name: 'TableUsage',
  components: {
    ApexChart: VueApexCharts,
  },
  data() {
    return {
      // Initialize your table usage data here
      series: [],
      chartOptions: {
        yaxis: {
          labels: {
            style: {
              colors: '#ffffff'
            }
          }
        },
        xaxis: {
          labels: {
            style: {
              colors: '#ffffff'
            }
          }
        },
        markers: {
          colors: ['#000000']
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar:{
            show: false
          }
        },
        grid: {
          row: {
            colors: ['#000000']
          },
          column: {
            colors: ['#000000']
          }
        },
        dataLabels: {
          enabled:true,
          style: {
            colors: ['#000000']
          }
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
          labels: {
            colors: '#ffffff'
          },
          width: 40,
          horizontalAlign: 'center',

          markers: {
            fillColors: [
              '#9C27B0',
              '#f3a75b',
              '#ffffff']
          }
        },
        fill: {
          colors: ['#F44336', '#E91E63', '#9C27B0']
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 1,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [{
                from: -30,
                to: 0,
                name: '0',
                color: '#000000'
              },
                {
                  from: 1,
                  to: 2,
                  name: '2',
                  color: '#005b54'
                },
                {
                  from: 3,
                  to: 4,
                  name: '4',
                  color: '#007c3a'
                },
                {
                  from: 5,
                  to: 6,
                  name: '6',
                  color: '#369d00'
                },
                {
                  from: 7,
                  to: 8,
                  name: '8',
                  color: '#92cb00'
                },
                {
                  from: 9,
                  to: 10,
                  name: '10',
                  color: '#ffd500'
                }
              ]
            }
          }
        },
        title: {
          text: 'テーブルごとの使用回数',
          style: {
            fontSize:  '22px',
            fontWeight:  'bold',
            color:  '#ffffff'
          },
        },
      },
    }
  },
  methods:{
    getCount(mode,first,last){
      getUseCountData({
        mode: mode,
        first:first,
        last: last,
      }).then((res)=>{
        //这里不需要判空 没有值会为0
        let items=res.data.result;
        this.update(items.Pets1,items.Pets2,items.Pets3,items.Pets4,items.Pri1,items.Pri2,items.Pri3,items.Pri4,items.Pri5,items.Pri6,items.Pri7,items.Pri8,items.Pri9,items.Pri10,items.Pri11,items.Pri12);
      })
    },
    update(Pets1,Pets2,Pets3,Pets4,Pri1,Pri2,Pri3,Pri4,Pri5,Pri6,Pri7,Pri8,Pri9,Pri10,Pri11,Pri12){
      this.series=[
        {
          "name": "月",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[0],
            },
            {
              "x": "Pets2",
              "y": Pets2[0]
            },
            {
              "x": "Pets3",
              "y": Pets3[0]
            },
            {
              "x": "Pets4",
              "y": Pets4[0]
            },
            {
              "x": "Pri1",
              "y": Pri1[0]
            },
            {
              "x": "Pri2",
              "y": Pri2[0]
            },
            {
              "x": "Pri3",
              "y": Pri3[0]
            },
            {
              "x": "Pri4",
              "y": Pri4[0]
            },
            {
              "x": "Pri5",
              "y": Pri5[0]
            },
            {
              "x": "Pri6",
              "y": Pri6[0]
            },
            {
              "x": "Pri7",
              "y": Pri7[0]
            },
            {
              "x": "Pri8",
              "y": Pri8[0]
            },
            {
              "x": "Pri9",
              "y": Pri9[0]
            },
            {
              "x": "Pri10",
              "y": Pri10[0]
            },
            {
              "x": "Pri11",
              "y": Pri11[0]
            },
            {
              "x": "Pri12",
              "y": Pri12[0]
            }
          ]
        },
        {
          "name": "火",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[1],
            },
            {
              "x": "Pets2",
              "y": Pets2[1]
            },
            {
              "x": "Pets3",
              "y": Pets3[1]
            },
            {
              "x": "Pets4",
              "y": Pets4[1]
            },
            {
              "x": "Pri1",
              "y": Pri1[1]
            },
            {
              "x": "Pri2",
              "y": Pri2[1]
            },
            {
              "x": "Pri3",
              "y": Pri3[1]
            },
            {
              "x": "Pri4",
              "y": Pri4[1]
            },
            {
              "x": "Pri5",
              "y": Pri5[1]
            },
            {
              "x": "Pri6",
              "y": Pri6[1]
            },
            {
              "x": "Pri7",
              "y": Pri7[1]
            },
            {
              "x": "Pri8",
              "y": Pri8[1]
            },
            {
              "x": "Pri9",
              "y": Pri9[1]
            },
            {
              "x": "Pri10",
              "y": Pri10[1]
            },
            {
              "x": "Pri11",
              "y": Pri11[1]
            },
            {
              "x": "Pri12",
              "y": Pri12[1]
            }
          ]
        },
        {
          "name": "水",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[2],
            },
            {
              "x": "Pets2",
              "y": Pets2[2]
            },
            {
              "x": "Pets3",
              "y": Pets3[2]
            },
            {
              "x": "Pets4",
              "y": Pets4[2]
            },
            {
              "x": "Pri1",
              "y": Pri1[2]
            },
            {
              "x": "Pri2",
              "y": Pri2[2]
            },
            {
              "x": "Pri3",
              "y": Pri3[2]
            },
            {
              "x": "Pri4",
              "y": Pri4[2]
            },
            {
              "x": "Pri5",
              "y": Pri5[2]
            },
            {
              "x": "Pri6",
              "y": Pri6[2]
            },
            {
              "x": "Pri7",
              "y": Pri7[2]
            },
            {
              "x": "Pri8",
              "y": Pri8[2]
            },
            {
              "x": "Pri9",
              "y": Pri9[2]
            },
            {
              "x": "Pri10",
              "y": Pri10[2]
            },
            {
              "x": "Pri11",
              "y": Pri11[2]
            },
            {
              "x": "Pri12",
              "y": Pri12[2]
            }
          ]
        },
        {
          "name": "木",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[3],
            },
            {
              "x": "Pets2",
              "y": Pets2[3]
            },
            {
              "x": "Pets3",
              "y": Pets3[3]
            },
            {
              "x": "Pets4",
              "y": Pets4[3]
            },
            {
              "x": "Pri1",
              "y": Pri1[3]
            },
            {
              "x": "Pri2",
              "y": Pri2[3]
            },
            {
              "x": "Pri3",
              "y": Pri3[3]
            },
            {
              "x": "Pri4",
              "y": Pri4[3]
            },
            {
              "x": "Pri5",
              "y": Pri5[3]
            },
            {
              "x": "Pri6",
              "y": Pri6[3]
            },
            {
              "x": "Pri7",
              "y": Pri7[3]
            },
            {
              "x": "Pri8",
              "y": Pri8[3]
            },
            {
              "x": "Pri9",
              "y": Pri9[3]
            },
            {
              "x": "Pri10",
              "y": Pri10[3]
            },
            {
              "x": "Pri11",
              "y": Pri11[3]
            },
            {
              "x": "Pri12",
              "y": Pri12[3]
            }
          ]
        },
        {
          "name": "金",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[4],
            },
            {
              "x": "Pets2",
              "y": Pets2[4]
            },
            {
              "x": "Pets3",
              "y": Pets3[4]
            },
            {
              "x": "Pets4",
              "y": Pets4[4]
            },
            {
              "x": "Pri1",
              "y": Pri1[4]
            },
            {
              "x": "Pri2",
              "y": Pri2[4]
            },
            {
              "x": "Pri3",
              "y": Pri3[4]
            },
            {
              "x": "Pri4",
              "y": Pri4[4]
            },
            {
              "x": "Pri5",
              "y": Pri5[4]
            },
            {
              "x": "Pri6",
              "y": Pri6[4]
            },
            {
              "x": "Pri7",
              "y": Pri7[4]
            },
            {
              "x": "Pri8",
              "y": Pri8[4]
            },
            {
              "x": "Pri9",
              "y": Pri9[4]
            },
            {
              "x": "Pri10",
              "y": Pri10[4]
            },
            {
              "x": "Pri11",
              "y": Pri11[4]
            },
            {
              "x": "Pri12",
              "y": Pri12[4]
            }
          ]
        },
        {
          "name": "土",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[5],
            },
            {
              "x": "Pets2",
              "y": Pets2[5]
            },
            {
              "x": "Pets3",
              "y": Pets3[5]
            },
            {
              "x": "Pets4",
              "y": Pets4[5]
            },
            {
              "x": "Pri1",
              "y": Pri1[5]
            },
            {
              "x": "Pri2",
              "y": Pri2[5]
            },
            {
              "x": "Pri3",
              "y": Pri3[5]
            },
            {
              "x": "Pri4",
              "y": Pri4[5]
            },
            {
              "x": "Pri5",
              "y": Pri5[5]
            },
            {
              "x": "Pri6",
              "y": Pri6[5]
            },
            {
              "x": "Pri7",
              "y": Pri7[5]
            },
            {
              "x": "Pri8",
              "y": Pri8[5]
            },
            {
              "x": "Pri9",
              "y": Pri9[5]
            },
            {
              "x": "Pri10",
              "y": Pri10[5]
            },
            {
              "x": "Pri11",
              "y": Pri11[5]
            },
            {
              "x": "Pri12",
              "y": Pri12[5]
            }
          ]
        },
        {
          "name": "日",
          "data": [
            {
              "x": "Pets1",
              "y": Pets1[6],
            },
            {
              "x": "Pets2",
              "y": Pets2[6]
            },
            {
              "x": "Pets3",
              "y": Pets3[6]
            },
            {
              "x": "Pets4",
              "y": Pets4[6]
            },
            {
              "x": "Pri1",
              "y": Pri1[6]
            },
            {
              "x": "Pri2",
              "y": Pri2[6]
            },
            {
              "x": "Pri3",
              "y": Pri3[6]
            },
            {
              "x": "Pri4",
              "y": Pri4[6]
            },
            {
              "x": "Pri5",
              "y": Pri5[6]
            },
            {
              "x": "Pri6",
              "y": Pri6[6]
            },
            {
              "x": "Pri7",
              "y": Pri7[6]
            },
            {
              "x": "Pri8",
              "y": Pri8[6]
            },
            {
              "x": "Pri9",
              "y": Pri9[6]
            },
            {
              "x": "Pri10",
              "y": Pri10[6]
            },
            {
              "x": "Pri11",
              "y": Pri11[6]
            },
            {
              "x": "Pri12",
              "y": Pri12[6]
            }
          ]
        }
      ]
    },

    formattedDate(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
  },
  created() {
    //刚进来显示本天到之前一个星期
    let time=new Date();
    let dateString = time.getTime();
    let last= this.formattedDate(dateString);

    const result = new Date();
    result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
    let first = this.formattedDate(result);
    this.getCount("all",first,last);

    emitter.on("set_data",(args)=>{
      if(args[0]==null){
        args[0]="all";
      }
      //第一次进来是今天
      if(args[2]==null){
        let time=new Date();
        let dateString = time.getTime();
        args[2]= this.formattedDate(dateString);
      }
      //七天前时间
      if(args[1]==null) {
        let time=new Date();
        let dateString = time.getTime();
        const result = new Date();
        result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
        args[1] = this.formattedDate(result);
      }
       this.getCount(args[0],args[1],args[2]);
    })

    emitter.on("login",()=>{
      //第一次进来是今天
      let time=new Date();
      let dateString = time.getTime();
      let last= this.formattedDate(dateString);

      //七天前时间
      const result = new Date();
      result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
      let first = this.formattedDate(result);

      this.getCount("all",first,last);
    })
  }
}
</script>

<style scoped>
/* Add your CSS here */
</style>
