<template>
  <div class="touch-in" @mouseover="isPanelVisible = true"/>
  <div class="config-panel"  @mouseleave="isPanelVisible = false" :class="{ 'panel-visible': isPanelVisible }">
    <button @click="logout()" class="b1">logout</button>
    <!-- Add your configuration options here -->
    <div class="mealtime-container">
      <p>mealtime</p>
      <select v-model="mealtime">
        <option v-for="option in mealtimeOptions" :key="option" :value="option"  >
          {{ option }}
        </option>
      </select>
    </div>
    <div class="date-container">
      <p>カレンダー:</p>
      <Datepicker  v-model="date" dark range :multi-calendars="{ solo: true }" :max-date="new Date()" />
    </div>

    <button @click="add(mealtime,date[0],date[1])" class="b2">もちろん</button>
  </div>
</template>

<script>
import {enable} from "core-js/internals/internal-metadata";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import emitter from "@/utils/emitter";

export default {

  name: 'ConfigPanel',
  methods: {
    enable,
    add(mealtime,date1,date2){

      date1 = this.formattedDate(date1);
      date2 = this.formattedDate(date2);

      //绑定
      emitter.emit("set_data",[this.mealtime,date1,date2]);
      // 消除显示
      this.mealtime='';
      this.date='';
    },
    logout(){
      localStorage.removeItem("token");
      location.replace("/#/login");
    },
    formattedDate(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
  },
  components: {
    Datepicker
  },
  data() {
    return {
      isPanelVisible:false,
      mealtime: '',
      mealtimeOptions: ['morning', 'dinner', 'all'],
      date: null
    }
  }
}
</script>

<style scoped>
.touch-in{
  width: 50px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.config-panel {
  width: 200px;
  height: 100%;
  position: fixed;
  left: -200px; /* Initially hide the panel */
  top: 0;
  background-color: #202020;
  transition: left 0.5s; /* Add transition */
  z-index: 100;
}

.panel-visible {
  left: 0; /* Show the panel when the mouse is over the #app div */
}

.mealtime-container {
  width: 100%; /* Span across the entire layout */
  background-color: black; /* Black background */
  color: white; /* White text color for visibility */
  padding: 0; /* Add some padding */
  margin-top: 240px;
}

select {
  width: 100%; /* Span across the entire layout */
  color: white; /* Black text color */
  background-color: black; /* White background for visibility */
}

.date-container {
  margin-top: 20px; /* Add some space at the top */
}
.b1{
  margin-top: 100px;
  color: white;
  background-color: rgba(0,0,0,0);
  border-radius: 5px;
}

.b2{
  margin-top: 30px;
  color: white;
  background-color: rgba(0,0,0,0);
  border-radius: 5px;
}
</style>
