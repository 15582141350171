<template>
  <div class="table-usage">
    <!-- Display the table usage data here -->

    <div id="chart">
      <ApexChart type="bar" height="350" width="500" :options="chartOptions" :series="series"></ApexChart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import {tablesTimeCountData} from "@/api/tables";
import emitter from "@/utils/emitter";
export default {
  name: 'TableUsage',
  components: {
    ApexChart: VueApexCharts,
  },

  data() {
    return {
      series: [
        {
          name: '空席',
          data: []
        }, {
          name: '食事中',
          data: []
        }, {
          name: 'リセット',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar:{
            show: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        xaxis: {
          categories: [
            'Pets1',
            'Pets2',
            'Pets3',
            'Pets4',
            'Pri1',
            'Pri10',
            'Pri11',
            'Pri12',
            'Pri2',
            'Pri3',
            'Pri4',
            'Pri5',
            'Pri6',
            'Pri7',
            'Pri8',
            'Pri9',
          ],
          labels: {
            style: {
              colors: '#ffffff'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#ffffff'
            }
          }
        },
        fill: {
          opacity: 1,
          colors: ['#9C27B0',
            '#f3a75b',
            '#ffffff']
        },
        dataLabels: {
          style: {
            colors: ['#000000']
          }
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
          labels: {
            colors: '#ffffff'
          },
          width: 100,
          horizontalAlign: 'center',

          markers: {
            fillColors: [
              '#9C27B0',
              '#f3a75b',
              '#ffffff']
          }
        },
        title: {
          text: 'テーブルごとの累積時間',
          style: {
            fontSize: '22px',
            fontWeight: 'bold',
            color: '#ffffff'
          },
        },
      },
    }
  },
  methods: {
    getDayTime(mode,first,last){
      tablesTimeCountData({
        mode: mode,
        first:first,
        last: last,
      }).then((res)=>{
        let items=res.data.result;
        let newData=[items.Pets1[0],items.Pets2[0],items.Pets3[0],items.Pets4[0],items.Pri1[0],items.Pri2[0],items.Pri3[0],items.Pri4[0],items.Pri5[0],items.Pri6[0],items.Pri7[0],items.Pri8[0],items.Pri9[0],items.Pri10[0],items.Pri11[0],items.Pri12[0]];
        let newData1=[items.Pets1[1],items.Pets2[1],items.Pets3[1],items.Pets4[1],items.Pri1[1],items.Pri2[1],items.Pri3[1],items.Pri4[1],items.Pri5[1],items.Pri6[1],items.Pri7[1],items.Pri8[1],items.Pri9[1],items.Pri10[1],items.Pri11[1],items.Pri12[1]];
        let newData2=[items.Pets1[2],items.Pets2[2],items.Pets3[2],items.Pets4[2],items.Pri1[2],items.Pri2[2],items.Pri3[2],items.Pri4[2],items.Pri5[2],items.Pri6[2],items.Pri7[2],items.Pri8[2],items.Pri9[2],items.Pri10[2],items.Pri11[2],items.Pri12[2]];
        this.updateChart(newData,newData1,newData2);
      })

    },
    updateChart(data1,data2,data3) {

      this.series = [{
        name:'空席',
        data: data1
      },{
        name:'食事中',
        data: data2
      },{
        name:'リセット',
        data: data3
      }]
    },
    formattedDate(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
  },

  created() {
    //刚进来显示本天到之前一个星期
    let time=new Date();
    let dateString = time.getTime();
    let last= this.formattedDate(dateString);

    const result = new Date();
    result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
    let first = this.formattedDate(result);
    this.getDayTime("all",first,last);

    emitter.on("set_data",(args)=>{
      if(args[0]==null){
        args[0]="all";
      }
      //第一次进来是今天
      if(args[2]==null){
        let time=new Date();
        let dateString = time.getTime();
        args[2]= this.formattedDate(dateString);
      }
      //七天前时间
      if(args[1]==null) {
        let time=new Date();
        let dateString = time.getTime();
        const result = new Date();
        result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
        args[1] = this.formattedDate(result);
      }
       this.getDayTime(args[0],args[1],args[2]);
    });

    emitter.on("login",()=>{
      //第一次进来是今天
      let time=new Date();
      let dateString = time.getTime();
      let last= this.formattedDate(dateString);

      //七天前时间
      const result = new Date();
      result.setTime(dateString - 6 * 24 * 60 * 60 * 1000);
      let first = this.formattedDate(result);

      this.getDayTime("all",first,last);
    })
  }
}
</script>
